export default class {

    static getRatioYScroll(_el) {
        var posElementInScreen = _el.offset().top-window.scrollY;
        var hScreen = window.innerHeight;
        let percent = (posElementInScreen/hScreen)*100;
        //console.log(_el, percent);
        var ratioY = 0;
        if (percent > 0){
            ratioY = (((posElementInScreen/hScreen)*100));
        }
        return ratioY;
    }

    static addTagManager(){
        //Google Tag Manager
        (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
                'gtm.start': new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-XXXXXXX');
        //End Google Tag Manager
    }

}
