import Utils from "./modules/utils";
import Header from "./modules/header";
import ShowOnScroll from "./modules/showOnScroll";

let jquery = require("jquery");
window.$ = window.jQuery = jquery; // notice the definition of global variables here
require("jquery-ui-dist/jquery-ui.js");

$(document).ready(function() {

  Header.bindHeaderActions();
  ShowOnScroll.bindShowOnScrollActions();

  // BANNER COOKIE
  var cookies = localStorage.getItem("cookies");

  if (!cookies) {
    $("#banner-cookies").addClass('show');
  } else {
    Utils.addTagManager();
  }

  $('#btn-close-banner-cookies').click(function (e) {
    $("#banner-cookies").removeClass('show');
    localStorage.setItem("cookies", "accepted");
    Utils.addTagManager();
  });
});

