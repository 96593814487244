export default class {

    static bindShowOnScrollActions() {

        // OBSERVA TODOS ELEMENTOS COM CLASSE obsElement E ADICIONA CLASSE QUANDO VISÍVEL
        var scroll = window.requestAnimationFrame ||
            // IE Fallback
            function(callback){ window.setTimeout(callback, 1000/60)};
        var elementsToAnime = document.querySelectorAll('.obsElement');
        var elementsToShow = document.querySelectorAll('.show-on-scroll');
        const threshold = 50;

        function loop() {

            Array.prototype.forEach.call(elementsToAnime, function(element){
                if (isElementInViewport(element)) {
                    element.classList.add('is-visible');
                } else {
                    // element.classList.remove('is-visible');
                }
            });

            Array.prototype.forEach.call(elementsToShow, function(element){
                if (isElementInViewport(element)) {
                    element.classList.add('is-visible');
                } else {
                    // element.classList.remove('is-visible');
                }
            });

            scroll(loop);
        }

        // Call the loop for the first time
        loop();

        // Helper function from: http://stackoverflow.com/a/7557433/274826
        function isElementInViewport(el) {
            // special bonus for those using jQuery
            if (typeof jQuery === "function" && el instanceof jQuery) {
                el = el[0];
            }
            var rect = el.getBoundingClientRect();
            return (
                (rect.top <= 0
                    && rect.bottom >= 0)
                ||
                (rect.bottom >= (window.innerHeight-threshold || document.documentElement.clientHeight-threshold) &&
                    rect.top <= (window.innerHeight-threshold || document.documentElement.clientHeight-threshold))
                ||
                (rect.top >= 0 &&
                    rect.bottom <= (window.innerHeight-threshold || document.documentElement.clientHeight-threshold))
            );
        }
    }
}
