import { Power2 } from "gsap/gsap-core";
import { gsap } from "gsap";

export default class {

    static bindHeaderActions() {

        let tlStart = gsap.timeline();
        if (sessionStorage.getItem("animation")){
            tlStart.to($('#header'), { duration: 0, opacity: 1, y: 0, ease: Power2.easeOut }, 0)
        } else {
            tlStart.to($('#header'), { duration: 1, opacity: 1, y: 0, ease: Power2.easeOut }, 0)
            sessionStorage.setItem("animation", "isViewed");
        }

        $('#btn-hamburger').click(function (e) {
            e.preventDefault();
            $(this).toggleClass('is-active');
            closeMenu();
        });

        function closeMenu() {
            // $(".bic-intensity").toggleClass('no-scroll');
            $("#header").toggleClass('open');
            $(".mask-menu").toggleClass('show');
        }

        $(".menu ul li a").click(function(){
            $('#nav-toggle').removeClass("is-active");
            $("#header").removeClass('open');
            $(".mask-menu").removeClass('show');
            // $(".bic-intensity").removeClass('no-scroll');
        });
    }

}
